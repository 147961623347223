<template>
  <div>
    <v-container
      class="v-container-fluid"
      fluid
    >
      <v-row>
        <v-col cols="12">
          <!-- button return -->
          <v-btn
            @click="$router.go(-1)"
            text
            class="pl-0"
          >
            <v-icon
              class="icon-return"
              size="15px"
            >
              mdi-arrow-left
            </v-icon>
            <span class="text-return mon-regular">{{ texts.subcatalog.buttonReturn }}</span>
          </v-btn>
          <!-- content title and filters -->
          <div class="mt-5">
            <v-row>
              <v-col
                cols="12"
                lg="7"
                md="7"
                sm="7"
              >
                <div class="display-flex align-items-center height-100">
                  <p class="title-section mon-bold">{{ texts.subcatalog.titleSection }}</p>
                </div>
              </v-col>
              <v-spacer></v-spacer>
              <v-col
                cols="12"
                lg="5"
                md="5"
                sm="5"
              >
                <div class="display-flex align-items-center height-100">
                  <v-text-field
                    v-model="sSearch"
                    solo
                    :label="texts.subcatalog.textSearch"
                    append-icon="mdi-magnify"
                    class="global-inputs mon-regular mod-input"
                    outlined
                    color="#000000"
                    dense
                  ></v-text-field>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    texts: {
      type: Object,
      default: "",
    },
  },
  name: "SubcatalogSearchbarLayout",
  data() {
    return {
      //VARIABLES
      sSearch: "",
    };
  },
  beforeMount() {
    this.$store.commit("setSearch", "");
  },
  methods: {},
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
      }
    },
    sSearch: lodash.debounce(function (val) {
      this.$store.commit("setSearch", this.sSearch);
    }, 500),
  },
};
</script>

<style>
.mod-input .v-input__slot {
  padding-right: 0px !important;
}

.mod-input .v-input__icon {
  background: transparent linear-gradient(228deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  height: 40px;
  width: 40px;
  border-top-right-radius: 9px;
  border-bottom-right-radius: 9px;
}
</style>

<style scoped>
.title-section {
  text-align: left;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  font-size: 35px;
  margin-bottom: 0px;
}

.icon-return {
  border-radius: 100%;
  width: 27px;
  height: 27px;
  background: transparent linear-gradient(265deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
}

.text-return {
  text-transform: initial;
  margin-left: 5px;
  font-size: 16px;
}

@media (max-width: 960px) {
  .hidden-from-tablet {
    display: none;
  }
}
</style>